import React from 'react'
import { constants } from 'helpers'
import { useIntl } from 'intl'
import { useDevice } from 'device'
import cx from 'classnames'

import { Image, Text } from 'components/dataDisplay'

import bottlesGif from './images/bottles.gif?src-loader'
import bottlesStatic from './images/bottles-static.jpg'
import cartGif from './images/cart.gif?src-loader'
import cartStatic from './images/cart-static.jpg'
import caseGif from './images/case.gif?src-loader'
import caseStatic from './images/case-static.jpg'
import handshakeGif from './images/handshake.gif?src-loader'
import handshakeStatic from './images/handshake-static.jpg'

import messages from './messages'


type FeatureItemListProps = {
  className: string
  isAiSummaryLandingHowEnabled: boolean
}

const FeatureItemList: React.FunctionComponent<FeatureItemListProps> = (props) => {

  const { className, isAiSummaryLandingHowEnabled } = props

  return (
    <div className={className}>
      <FeatureItem
        animationImageSrc={bottlesGif}
        staticImageSrc={bottlesStatic}
        title={messages.features.bottles.title}
        subtitle={{
          ...(isAiSummaryLandingHowEnabled ? messages.features.bottles.subtitleAiSummary : messages.features.bottles.subtitle),
          values: {
            fragrancesAmount: constants.businessLogic.fragrancesAmount,
          },
        }}
      />
      <FeatureItem
        animationImageSrc={cartGif}
        staticImageSrc={cartStatic}
        title={messages.features.cart.title}
        subtitle={messages.features.cart.subtitle}
      />
      <FeatureItem
        animationImageSrc={caseGif}
        staticImageSrc={caseStatic}
        title={messages.features.case.title}
        subtitle={messages.features.case.subtitle}
      />
      <FeatureItem
        animationImageSrc={handshakeGif}
        staticImageSrc={handshakeStatic}
        title={messages.features.handshake.title}
        subtitle={messages.features.handshake.subtitle}
      />
    </div>
  )
}

type FeatureItemProps = {
  className?: string
  animationImageSrc: Image.Src
  staticImageSrc: string
  title: string | Intl.Message
  subtitle: string | Intl.Message
}

const FeatureItem: React.FunctionComponent<FeatureItemProps> = (props) => {
  const { className, animationImageSrc, staticImageSrc, title, subtitle } = props

  const intl = useIntl()

  const { isMobile } = useDevice()

  return (
    <div className={cx(className, 'flex items-center', isMobile ? 'gap-32' : 'min-w-[273rem] flex-col gap-16 text-center')}>
      <picture>
        <source
          srcSet={staticImageSrc}
          media="(prefers-reduced-motion: reduce)"
          width={80}
          height={80}
        />
        <Image
          className="size-[80rem] flex-none"
          src={animationImageSrc}
          alt={intl.formatMessage(messages.imageAlt)}
          remWidth={80}
          lazy
          unoptimized
        />
      </picture>
      <div className="flex-1">
        <Text
          style="h6"
          message={title}
          html
        />
        <Text
          className="mt-8"
          style="p4"
          message={subtitle}
          html
        />
      </div>
    </div>
  )
}

export default React.memo(FeatureItemList)
