/* eslint-disable stylistic/max-len */

import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { useCountdown } from 'hooks'
import { useDevice } from 'device'
import { constants, twcx } from 'helpers'

import { Text, type TextStyle, type TextColor } from 'components/dataDisplay'

import messages from './messages'


export const bgColorToClassName = {
  'black': 'bg-black',
  'white': 'bg-white',
  'gray-10': 'bg-gray-10',
  'light-beige': 'bg-light-beige',
  'gold-30': 'bg-gold-30',
  'gold-70': 'bg-gold-70',
} as const

export type Size = 24 | 36 | 56
export type BgColor = keyof typeof bgColorToClassName

const baseBoxClassName = 'flex items-center justify-center rounded'

type GetParamsBySizeProps = {
  size: Size
  countdownColor?: TextColor
  captionColor?: TextColor
  backgroundColor?: BgColor
  withHours?: boolean
  hoursCaption?: string
  minutesCaption?: string
  secondsCaption?: string
}

const getParamsBySize = ({
  size,
  countdownColor,
  captionColor,
  backgroundColor,
  withHours,
  hoursCaption = 'hours',
  minutesCaption = 'minutes',
  secondsCaption = 'seconds',
}: GetParamsBySizeProps) => {
  const backgroundColorClass = backgroundColor ? bgColorToClassName[backgroundColor] : 'bg-light-beige'
  const countdownColorClass = countdownColor ? `text-${countdownColor}` : ''
  const captionColorClass = captionColor ? `text-${captionColor}` : ''
  const className = cx(baseBoxClassName, backgroundColorClass, countdownColorClass)

  if (size === 24) {
    return {
      textStyle: 'text-sh6 text-[12rem]',
      format: `
        ${withHours ? `[<div class="mr-8"><div class="w-s24 h-s24 ${className}">] HH [</div><div class="${captionColorClass} text-t8">${hoursCaption}</div></div><div class="${captionColorClass}">:</div>]` : ''}
        [<div class="mr-8 ${withHours ? 'ml-8' : ''}"><div class="w-s24 h-s24 ${className}">] mm [</div><div class="${captionColorClass} text-t8">${minutesCaption}</div></div><div class="${captionColorClass}">:</div>]
        [<div class="ml-8"><div class="w-s24 h-s24 ${className}">] ss [</div><div class="${captionColorClass} text-t8">${secondsCaption}</div></div>]
      `,
    }
  }

  if (size === 36) {
    return {
      textStyle: 'text-sh6',
      format: `
        ${withHours ? `[<div class="mr-8"><div class="w-s36 h-s36 ${className}">] HH [</div><div class="${captionColorClass} text-t3">${hoursCaption}</div></div><div class="${captionColorClass}">:</div>]` : ''}
        [<div class="mr-8 ${withHours ? 'ml-8' : ''}"><div class="w-s36 h-s36 ${className}">] mm [</div><div class="${captionColorClass} text-t3">${minutesCaption}</div></div><div class="${captionColorClass}">:</div>]
        [<div class="ml-8"><div class="w-s36 h-s36 ${className}">] ss [</div><div class="${captionColorClass} text-t3">${secondsCaption}</div></div>]
      `,
    }
  }

  if (size === 56) {
    return {
      textStyle: 'text-sh3',
      format: `
        ${withHours ? `[<div class="mr-8"><div class="w-s56 h-s56 ${className}">] HH [</div><div class="mt-4 ${captionColorClass} text-p5">${hoursCaption}</div></div><div class="${captionColorClass}">:</div>]` : ''}
        [<div class="mr-8 ${withHours ? 'ml-8' : ''}"><div class="w-s56 h-s56 ${className}">] mm [</div><div class="mt-4 ${captionColorClass} text-p5">${minutesCaption}</div></div><div class="${captionColorClass}">:</div>]
        [<div class="ml-8"><div class="w-s56 h-s56 ${className}">] ss [</div><div class="mt-4 ${captionColorClass} text-p5">${secondsCaption}</div></div>]
      `,
    }
  }
}

type StyledTimerProps = {
  title?: string | Intl.Message
  className?: string
  cookieName?: string
  initialTime?: number
  backgroundColor?: BgColor
  countdownColor?: TextColor
  captionColor?: TextColor
  format?: string
  timerClassname?: string
  withHours?: boolean
  withTitle?: boolean
  titleClassname?: string
  titleColor?: TextColor
  titleStyle?: TextStyle
  showExpiredTimer?: boolean
  size: Size
  onExpired?: () => void
  'data-testid'?: string
  hoursCaption?: string
  minutesCaption?: string
  secondsCaption?: string
}

const StyledTimer: React.FC<StyledTimerProps> = (props) => {
  const {
    className,
    initialTime,
    backgroundColor,
    countdownColor,
    captionColor,
    title,
    titleClassname: customTitleClassname,
    titleColor: customTitleColor,
    titleStyle: customTitleStyle,
    timerClassname,
    withHours,
    withTitle,
    size = 56,
    cookieName = constants.cookieNames.rootCountdown,
    showExpiredTimer,
    'data-testid': dataTestId,
    onExpired,
    hoursCaption,
    minutesCaption,
    secondsCaption,
  } = props

  const { isMobile } = useDevice()

  const countdownRef = useRef<HTMLDivElement>()

  const { format, textStyle } = getParamsBySize({
    size,
    countdownColor,
    captionColor,
    backgroundColor,
    withHours,
    hoursCaption,
    minutesCaption,
    secondsCaption,
  })

  const { countdownMessage, isExpired } = useCountdown({
    nodeRef: countdownRef,
    cookieName,
    initialTime,
    format: props.format || format,
    message: messages.time,
  })

  useEffect(() => {
    if (isExpired && typeof onExpired === 'function') {
      onExpired()
    }
  }, [ isExpired, onExpired ])

  const titleClassname = isMobile ? 'mb-12' : 'mb-24'
  const titleColor = isMobile ? 'white' : 'gold-70'
  const titleStyle = isMobile ? 't1' : 'sh3'

  if (isExpired && !showExpiredTimer) {
    return null
  }

  return (
    <div className={className}>
      {
        withTitle && (
          <Text
            className={customTitleClassname ?? titleClassname}
            message={title ?? messages.title}
            style={customTitleStyle ?? titleStyle}
            color={customTitleColor ?? titleColor}
          />
        )
      }
      <div
        className={twcx('inline-flex items-baseline text-center', textStyle, timerClassname)}
        ref={countdownRef}
        dangerouslySetInnerHTML={{ __html: countdownMessage }}
        data-testid={dataTestId}
      />
    </div>
  )
}


export default React.memo(StyledTimer)
