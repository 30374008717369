import React from 'react'

import { useProductsInQueueContext } from 'containers/ProductsInQueueProvider/ProductsInQueueProvider'

import { type ProductListProps } from 'compositions/landings/BestsellersBlock/components/ProductList/ProductList'

import { type HomePageViewProps } from 'pages/landing/View/View'


import ProductsFeedSection from '../ProductsFeedSection/ProductsFeedSection'
import BestsellersSection from '../BestsellersSection/BestsellersSection'


export type ProductsSectionProps = {
  className?: string
  customProducts?: HomePageViewProps['customProductsBlock']
  fetchMoreButtonTitle?: ProductListProps['fetchMoreButtonTitle']
  fetchMoreButtonStyle?: ProductListProps['fetchMoreButtonStyle']
}

const ProductsSection: React.FunctionComponent<ProductsSectionProps> = (props) => {
  const { className, customProducts, fetchMoreButtonTitle, fetchMoreButtonStyle } = props

  const { updateCount } = useProductsInQueueContext()

  const buttonProps = {
    withNotification: false,
    withNonAuthRedirect: false,
    withErrorModals: false,
    force: true, // to add lep products to queue for users with subscription.hasNeverSubscribed
    metadata: {
      placement: 'Bestsellers',
    },
    onClick: updateCount,
  }

  if (customProducts?.slugs || customProducts?.sku) {
    return (
      <ProductsFeedSection
        className={className}
        title={customProducts.title}
        slugs={customProducts.slugs}
        sku={customProducts.sku}
        withoutLink={customProducts.withoutLink}
        withRetailPrice={customProducts.withRetailPrice}
        excludeLabels={customProducts.excludeLabels}
        buttonProps={buttonProps}
      />
    )
  }

  return (
    <BestsellersSection
      className={className}
      title={customProducts?.title}
      buttonProps={buttonProps}
      fetchMoreButtonTitle={fetchMoreButtonTitle}
      fetchMoreButtonStyle={fetchMoreButtonStyle}
    />
  )
}


export default React.memo(ProductsSection)
