import React from 'react'
import { useEntry, useVisibilityTrigger } from 'intersection-observer'
import { useDevice } from 'device'
import { usePathname } from 'router'
import links from 'links'
import loadable from '@loadable/component'

import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useAb } from 'hooks'
import { track } from 'analytics'
import type { Label } from 'typings/graphql'

import { Bone } from 'components/layout'
import type { ButtonProps } from 'components/inputs'
import ProductsInQueueProvider from 'containers/ProductsInQueueProvider/ProductsInQueueProvider'

import ContinueStickyBar from 'compositions/landings/ContinueStickyBar/ContinueStickyBar'
import SubscribeButton, { type SubscribeButtonProps } from 'compositions/buttons/SubscribeButton/SubscribeButton'

import AiSummarySection from './components/AiSummarySection/AiSummarySection'
import HeroSection, { type HeroSectionProps } from './components/HeroSection/HeroSection'
import PressSection from './components/PressSection/PressSection'
import BottomBanner from './components/BottomBanner/BottomBanner'
import QuizSection from './components/QuizSection/QuizSection'
import BrandsRow from './components/BrandsRow/BrandsRow'
import ProductsSection, { type ProductsSectionProps } from './components/ProductsSection/ProductsSection'
import BrandsSection from './components/BrandsSection/BrandsSection'
import SocialProofSection from './components/SocialProofSection/SocialProofSection'
import HowItWorksAsHero from './components/HowItWorksAsHero/HowItWorksAsHero'

import messages from './messages'


const HowItWorksSectionWithAnimations = loadable(
  () => import('./components/HowItWorksSectionWithAnimations/HowItWorksSectionWithAnimations'),
  { fallback: <Bone className="bg-light-beige" pw={100} h={656} /> }
)

type BlockWithCustomButton = 'brandsQuality' | 'bottomBanner' | 'stickyBar'

export type HomePageCustomBlockButton = Partial<Pick<ButtonProps, 'className' | 'title' | 'width' | 'fullWidth' | 'fullWidthOnMobile' | 'onClick'>> & {
  actionType: 'quiz' | 'subscribe' | 'link'
  title?: string | Intl.Message
  size?: Extract<ButtonProps['size'], 38 | 56>
  style?: Extract<ButtonProps['style'], 'primary' | 'gold-30'>
  to?: string
}

const offerLandingPagePaths = [ links.offer, links.offerMen ]
  .map((link) => link.replace('(.*)', '').replace(':cpn', ''))
  .join('|')

const offerLandingPageRegExp = new RegExp(`^(${offerLandingPagePaths})`)

export type HomePageViewProps = {
  isFetching: boolean
  price: string
  eventDiscountText?: SubscriptionModule.EventDiscountText
  offerType?: SubscriptionModule.OfferType
  discount?: string
  priceWithDiscount?: string
  extraShippingPrice?: string
  couponCode?: string
  country?: Intl.CountryCode
  heroCustomBlocks?: HeroSectionProps['customBlocks']
  customProductsBlock?: {
    title: Intl.Message
    slugs?: string[]
    sku?: string[]
    withoutLink?: boolean
    withRetailPrice?: boolean
    excludeLabels?: Label[]
  }
  customBlocksButtons?: {
    [k in BlockWithCustomButton]?: HomePageCustomBlockButton
  }
  fetchMoreButtonTitle?: ProductsSectionProps['fetchMoreButtonTitle']
  subscribeButton?: SubscribeButtonProps
}

const View: React.FunctionComponent<HomePageViewProps> = (props) => {
  const {
    couponCode,
    country,
    discount,
    eventDiscountText,
    extraShippingPrice,
    isFetching,
    offerType,
    price,
    priceWithDiscount,
    heroCustomBlocks,
    customBlocksButtons,
    customProductsBlock,
    subscribeButton,
    fetchMoreButtonTitle,
  } = props

  const { isMobile } = useDevice()

  const defaultSectionMargin = isMobile ? 'mt-64' : 'mt-80'

  const pathname = usePathname()

  const isSocialProofAfterHowItWorksEnabled = useAb(constants.abTests.homeSocialProof) === 'b'
  const isHeroWithSliderAbEnabled = useAb(constants.abTests.homeHeroWithSlider) === 'b'
  const isHowItWorksAsHeroExperimentVersion = useAb(constants.abTests.homeHowItWorksAsHero)

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryLandingBlockFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryLandingModule)

  const isAiSummaryLandingBlockEnabled = isAiSummaryEnabled && isAiSummaryLandingBlockFeatureEnabled

  // TODO: Delete after CRO-606 ab test is over
  const socialProofSectionRef = useVisibilityTrigger(() => {
    track('Social proof display')
  })

  const isHomePage = (
    pathname === links.home
    || pathname === links.homeMen
    || offerLandingPageRegExp.test(pathname)
  )
  const isTimerEnabled = (
    isHomePage
    || Boolean(heroCustomBlocks?.timer)
  )

  const isCanada = country === 'CA'

  const isHowItWorksAsHeroEnabled = isHomePage && (isHowItWorksAsHeroExperimentVersion === 'b' || isHowItWorksAsHeroExperimentVersion === 'c')

  const showOfferMessage = (isHomePage || ([ links.freeTrial, links.secondMonthFree, links.freePerfume ].includes(pathname))) && !isCanada

  // narrow rootMargin to tiny line to hide StickyBar before global footer
  const [ stickyBarAreaRef, stickyBarAreaEntry ] = useEntry({ observerProps: { rootMargin: '0% 0% -90%' } })
  const isStickyBarVisible = stickyBarAreaEntry?.isIntersecting

  const messageOfferType = offerType ?? 'noOffer'
  const startButtonWithCrossedPriceMessage = {
    ...messages.offerTitles[messageOfferType], values: { price, priceWithDiscount },
  }

  return (
    <ProductsInQueueProvider>
      {
        isHowItWorksAsHeroEnabled ? (
          <HowItWorksAsHero
            className={isMobile ? 'mt-32' : 'mt-80'}
            startButtonMessage={showOfferMessage ? startButtonWithCrossedPriceMessage : undefined}
            discount={discount}
            offerType={offerType}
            price={price}
            priceWithDiscount={priceWithDiscount}
            extraShippingPrice={extraShippingPrice}
          />
        ) : (
          <HeroSection
            isFetching={isFetching}
            offerType={offerType}
            discount={discount}
            price={price}
            priceWithDiscount={priceWithDiscount}
            extraShippingPrice={extraShippingPrice}
            customBlocks={heroCustomBlocks}
            isTimerEnabled={isTimerEnabled}
            startButtonMessage={showOfferMessage ? startButtonWithCrossedPriceMessage : undefined}
            withSlider={isHeroWithSliderAbEnabled}
          />
        )
      }
      <BrandsRow style={isHeroWithSliderAbEnabled ? 'black' : 'white'} />
      {
        !isHowItWorksAsHeroEnabled && (
          <HowItWorksSectionWithAnimations
            className={defaultSectionMargin}
            startButtonMessage={showOfferMessage && isMobile ? startButtonWithCrossedPriceMessage : undefined}
            showStartButton={!subscribeButton}
          />
        )
      }
      {
        Boolean(subscribeButton) && (
          <div className="mt-32 text-center">
            <SubscribeButton {...subscribeButton} />
          </div>
        )
      }
      <div ref={socialProofSectionRef} />
      {
        isSocialProofAfterHowItWorksEnabled && (
          <SocialProofSection
            className={defaultSectionMargin}
            startButtonMessage={showOfferMessage ? startButtonWithCrossedPriceMessage : undefined}
          />
        )
      }
      <QuizSection className={defaultSectionMargin} />
      {
        isAiSummaryLandingBlockEnabled ? (
          <AiSummarySection className={isMobile ? 'mt-72' : 'mt-80'} />
        ) : (
          <BrandsSection
            className={isMobile ? 'mt-72' : 'mt-80'}
            customButton={customBlocksButtons?.brandsQuality}
          />
        )
      }
      <PressSection className={defaultSectionMargin} />
      <div ref={stickyBarAreaRef}>
        <ProductsSection
          className={defaultSectionMargin}
          customProducts={customProductsBlock}
          fetchMoreButtonTitle={fetchMoreButtonTitle}
          fetchMoreButtonStyle={subscribeButton ? 'primary' : undefined}
        />
      </div>
      <BottomBanner
        className={defaultSectionMargin}
        customButton={customBlocksButtons?.bottomBanner}
      />
      <ContinueStickyBar
        isVisible={isStickyBarVisible}
        eventDiscountText={eventDiscountText}
        customButton={customBlocksButtons?.stickyBar}
        coupon={couponCode}
      />
    </ProductsInQueueProvider>
  )
}

export default View
