import React from 'react'
import { useDevice } from 'device'
import cx from 'classnames'

import { useProducts } from 'modules/products'
import type { Label } from 'typings/graphql'

import { Text } from 'components/dataDisplay'
import { Bone, WidthContainer } from 'components/layout'

import ProductCardSmallSkeleton from 'compositions/productCards/ProductCardSmallSkeleton/ProductCardSmallSkeleton'
import ProductCardDetailedSkeleton from 'compositions/productCards/ProductCardDetailedSkeleton/ProductCardDetailedSkeleton'
import ProductCardSmall from 'compositions/productCards/ProductCardSmall/ProductCardSmall'
import ProductCardDetailed from 'compositions/productCards/ProductCardDetailed/ProductCardDetailed'
import type { ProductListProps } from 'compositions/landings/BestsellersBlock/components/ProductList/ProductList'


export type ProductsFeedSectionProps = {
  className?: string
  title: string | Intl.Message
  slugs?: string[]
  sku?: string[]
  withoutLink?: boolean
  withRetailPrice?: boolean
  excludeLabels?: Label[]
  buttonProps: ProductListProps['buttonProps']
}


const ProductsFeedSection: React.FunctionComponent<ProductsFeedSectionProps> = (props) => {
  const { className, title, slugs, sku, withoutLink, withRetailPrice, excludeLabels, buttonProps } = props
  const { isMobile } = useDevice()

  const { isFetching, products = [] } = useProducts({
    variables: {
      input: {
        slugs,
        sku,
        visibility: [ 'LANDING', 'VISIBLE' ],
      },
      isDrift: true,
    },
    skip: !slugs?.length && !sku?.length,
  })

  if (isFetching) {
    return (
      <WidthContainer className={className}>
        <Bone pw={100} h={37} bgColor="light-beige" />
        <div className={cx('mt-24 grid grid-flow-dense items-stretch gap-16', isMobile ? 'grid-cols-2' : 'grid-cols-3')}>
          {
            Array(isMobile ? 2 : 3).fill(0).map((_, index) => (
              isMobile ? (
                <ProductCardSmallSkeleton key={index} />
              ) : (
                <ProductCardDetailedSkeleton key={index} />
              )
            ))
          }
        </div>
      </WidthContainer>
    )
  }

  const productsContainerClassName = cx(className, 'mt-24 grid grid-flow-dense items-stretch gap-16', isMobile ? 'grid-cols-2-center' : 'grid-cols-3-center')

  return (
    <WidthContainer className={className}>
      <Text
        className={isMobile ? 'mb-32 mt-64' : 'mb-40 mt-80'}
        message={title}
        style={isMobile ? 'h4' : 'h3'}
        html
      />

      <div className={productsContainerClassName} role="list">
        {
          Boolean(products?.length) && (
            products.map((data) => (
              React.createElement(isMobile ? ProductCardSmall : ProductCardDetailed, {
                key: data.id,
                data,
                withoutLink,
                replaceRatingWithRetailPrice: withRetailPrice,
                buttonProps,
                excludeLabels,
                dataAttributes: { role: 'listitem' },
              })
            ))
          )
        }
      </div>
    </WidthContainer>
  )
}


export default ProductsFeedSection
