import React, { useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useAb, usePrefersReducedMotion } from 'hooks'
import { WidthContainer } from 'components/layout'
import { Text, Video } from 'components/dataDisplay'
import DiscountBadgeBase from 'compositions/DiscountBadgeBase/DiscountBadgeBase'
import StyledTimer from 'compositions/StyledTimer/StyledTimer'

import FeatureItemList from '../FeatureItemList/FeatureItemList'
import GetStartedButton from '../GetStartedButton/GetStartedButton'

import CaseImagesAnimation from './components/CaseImagesAnimation/CaseImagesAnimation'

import vialsVideo from './images/vials.mp4'

import type { MessagesByOffer } from '../HeroSection/HeroSection'


import messages from './messages'


type HowItWorksAsHeroProps = {
  className: string
  startButtonMessage: string | Intl.Message
  discount: string
  offerType: SubscriptionModule.OfferType
  price: string
  priceWithDiscount: string
  extraShippingPrice: string
}

const messagesByOffer: MessagesByOffer = {
  freeTrial: messages.header.freeTrial,
  free2ndMonth: messages.header.freeMonth,
  freeProduct: messages.header.freeProduct,
  discount: messages.header.discount,
}

// TODO Delete if CRO-621 fails
const HowItWorksAsHero: React.FC<HowItWorksAsHeroProps> = (props) => {
  const { className, startButtonMessage, discount, offerType, price, priceWithDiscount, extraShippingPrice } = props

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryLandingHowFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryLandingHow)
  const isAiSummaryLandingHowEnabled = isAiSummaryEnabled && isAiSummaryLandingHowFeatureEnabled

  const isHowItWorksAsHeroEnabledVersionC = useAb(constants.abTests.homeHowItWorksAsHero) === 'c'

  const { isMobile } = useDevice()
  const prefersReducedMotion = usePrefersReducedMotion()

  const offerTextStylesMap = useMemo(() => ({
    discount: isMobile ? 'text-[24rem]/[24rem] -mt-8' : 'text-[32rem]/[32rem] -mt-8',
    freeTrial: isMobile ? 'text-[20rem]/[20rem]' : 'text-[32rem]/[32rem]',
    free2ndMonth: isMobile ? 'text-[14rem]/[14rem]' : 'text-[21rem]/[21rem]',
    freeProduct: isMobile ? 'text-[16rem]/[16rem]' : 'text-[22rem]/[22rem]',
  }), [ isMobile ])

  const title = messagesByOffer[offerType]?.title || messages.header.noDiscount.title
  const subtitle = messagesByOffer[offerType]?.text || messages.header.noDiscount.text

  const titleMessage = {
    ...title,
    values: {
      discount,
      price,
      priceWithDiscount: priceWithDiscount || price,
      fragrancesAmount: constants.businessLogic.fragrancesAmount,
      highlightTextClassName: isMobile ? 'text-gold-70' : 'text-gold-50',
    },
  }

  const subtitleMessage = {
    ...subtitle,
    values: {
      price,
      extraShippingPrice,
      priceWithDiscount: priceWithDiscount || price,
    },
  }

  return (
    <WidthContainer className={cx(className, 'flex flex-col items-center')}>
      <Text
        className={cx('max-w-752 text-center', isMobile ? 'mb-16' : 'mb-24')}
        style={isMobile ? 'h4' : 'h2'}
        message={titleMessage}
        html
      />
      <Text
        className={cx('max-w-752 text-center', isMobile ? 'mb-16' : 'mb-48')}
        style={isMobile ? 'p2' : 'sh3'}
        message={subtitleMessage}
        html
      />
      <div className={cx('flex', isMobile ? 'mt-12 flex-col-reverse gap-40' : 'items-center gap-64')}>
        <FeatureItemList
          className={cx('grid gap-32', isMobile ? 'grid-cols-1 gap-40' : 'max-w-[600rem] grid-cols-2')}
          isAiSummaryLandingHowEnabled={isAiSummaryLandingHowEnabled}
        />
        <div className="relative h-fit text-center">
          <StyledTimer
            className={isHowItWorksAsHeroEnabledVersionC ? 'mb-32' : 'mb-48'}
            withTitle
            titleColor="black"
            titleClassname={cx('mb-12 text-center font-normal', isMobile ? '!text-p5' : '!text-h6')}
            title={messages.timerCaption}
            backgroundColor="gold-30"
            size={56}
            withHours={false}
            minutesCaption="minutes"
            secondsCaption="seconds"
          />
          {
            isHowItWorksAsHeroEnabledVersionC ? (
              <CaseImagesAnimation className={cx('z-2 relative text-center', isMobile ? 'mb-24' : 'mb-36')} />
            ) : (
              <Video
                className={isMobile ? '-mt-24  w-[376rem]' : '-mt-48 w-[496rem]'}
                url={vialsVideo}
                type="mp4"
                autoplay={!prefersReducedMotion}
                muted
                loop
                withBackground={false}
              />
            )
          }
          <Text
            className={cx(
              'z-2 relative text-center',
              isMobile ? '-mt-24' : '-mt-36',
              offerType !== null ? isMobile ? ' ml-56' : 'ml-88' : ''
            )}
            style={isMobile ? 'p5' : 'p2'}
            message={messages.vialInfo}
          />
          {
            offerType !== null && (
              <DiscountBadgeBase className={cx('font-tt-norms-pro z-2 absolute font-bold uppercase', isMobile ? '-bottom-8 size-96' : '-bottom-24 size-[136rem]')}>
                <div className="flex flex-col justify-center gap-4">
                  <Text
                    style={null}
                    className={cx('text-gold-30', offerTextStylesMap[offerType])}
                    message={{ ...messages.bannerMessages.offer[offerType], values: { discount } }}
                    html
                  />
                  <Text
                    style={null}
                    className={cx('text-white', isMobile ? 'text-[8rem]/[8rem]' : 'text-[12rem]/[12rem]')}
                    message={messages.bannerMessages.freeCase}
                  />
                </div>
              </DiscountBadgeBase>
            )
          }
        </div>
      </div>
      <GetStartedButton
        className="mb-48 mt-56 w-[384rem]"
        title={startButtonMessage}
        size={56}
        fullWidthOnMobile
        placement="How it works"
      />
    </WidthContainer>
  )
}

export default React.memo(HowItWorksAsHero)
