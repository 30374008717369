export default {
  header: {
    noDiscount: {
      title: {
        en: `
        Over {fragrancesAmount} <br class="onlyMobile"/> designer scents,<br/> <span class="{highlightTextClassName}">{price} a month</span>
        `,
      },
      text: {
        en: 'Skip the department store. We\'ll deliver new designer scents to you every month.',
      },
    },
    discount: {
      title: {
        en: `
        Over {fragrancesAmount}<br class="onlyMobile"/> designer scents,<br class="onlyMobile"/>
         <s>{price}</s> <span class="{highlightTextClassName}">{priceWithDiscount} a month</span>
        `,
      },
      text: {
        en: 'Skip the department store. We\'ll deliver new designer scents to you every month.',
      },
    },
    freeTrial: {
      title: {
        en: `Over {fragrancesAmount} <br class="onlyMobile"/> designer scents,<br/>
        <span class="{highlightTextClassName} whitespace-nowrap">claim your free <br/> trial</span>`,
      },
      text: {
        en: `Skip the department store and have designer scents delivered directly to you.<br/>Your first month is free, just pay {extraShippingPrice}
        for shipping and handling.<br/> Your second month will be {price}.`,
      },
    },
    freeProduct: {
      title: {
        en: `
        Over {fragrancesAmount}<br class="onlyMobile"/> designer scents,
        <br /> <span class="{highlightTextClassName}">claim your free perfume</span>
        `,
      },
      text: {
        en: 'Skip the department store and have designer scents delivered directly to you for {price} a month—we\'ll include a free scent in your first month\'s order.',
      },
    },
    freeMonth: {
      title: {
        en: `
        Over {fragrancesAmount}<br class="onlyMobile"/> designer scents,
        <br /> <span class="{highlightTextClassName}">get your second month free</span>
        `,
      },
      text: {
        en: 'Skip the department store and have designer scents delivered directly to you—your first month will be {price}, your second month is on us.',
      },
    },
  },
  bannerMessages: {
    offer: {
      discount: {
        en: '{discount}<br/>off',
      },
      freeTrial: {
        en: 'Free<br/>trial',
      },
      free2ndMonth: {
        en: 'Second<br/>month<br/>free',
      },
      freeProduct: {
        en: 'Free<br/>perfume',
      },
    },
    freeCase: {
      en: '+ a free case',
    },
  },
  vialInfo: {
    en: 'Travel-size 8mL: ~120 sprays, lasts 30 days.',
  },
  timerCaption: {
    en: 'Deal ends in:',
  },
  imageAlt: {
    en: 'How it works',
  },
}
