import links from 'links'
import { usePathname } from 'router'

import { type HomePageViewProps } from 'pages/landing/View/View'

import messages from './messages'


const getCustomProductsBlockByPathname = (pathname: string): HomePageViewProps['customProductsBlock'] | null => {
  if (pathname === links.andreDrummond) {
    return {
      title: messages.andreDrummond.productsBlockTitle,
      slugs: [
        '2795', // Furla Irresistible
        '3026', // Chris Collins African Rooibos
        '2386', // Parfums de Marly Layton
        '943571', // Versace Bright Crystal
        '872', // Confessions Of A Rebel Get A Room
        /**
SB3047VL1
SB3254VL1
SB2645VL1
968VL1
SB1144VL1
         */
      ],
    }
  }

  return null
}

const useLandingCustomProductsBlock = () => {
  const pathname = usePathname()

  return getCustomProductsBlockByPathname(pathname)
}


export default useLandingCustomProductsBlock
