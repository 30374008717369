import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePrefersReducedMotion } from 'hooks'
import { Image } from 'components/dataDisplay'

import vialImageDesktop from './images/desktop/vial.jpg?src-loader'
import vialImageMobile from './images/mobile/vial.jpg?src-loader'

import case1Desktop from './images/desktop/caseImages/case_1.jpg?src-loader'
import case2Desktop from './images/desktop/caseImages/case_2.jpg?src-loader'
import case3Desktop from './images/desktop/caseImages/case_3.jpg?src-loader'
import case4Desktop from './images/desktop/caseImages/case_4.jpg?src-loader'
import case5Desktop from './images/desktop/caseImages/case_5.jpg?src-loader'

import label1Desktop from './images/desktop/labelsImages/label_1.jpg?src-loader'
import label2Desktop from './images/desktop/labelsImages/label_2.jpg?src-loader'
import label3Desktop from './images/desktop/labelsImages/label_3.jpg?src-loader'
import label4Desktop from './images/desktop/labelsImages/label_4.jpg?src-loader'
import label5Desktop from './images/desktop/labelsImages/label_5.jpg?src-loader'


import case1Mobile from './images/mobile/caseImages/case_1.jpg?src-loader'
import case2Mobile from './images/mobile/caseImages/case_2.jpg?src-loader'
import case3Mobile from './images/mobile/caseImages/case_3.jpg?src-loader'
import case4Mobile from './images/mobile/caseImages/case_4.jpg?src-loader'
import case5Mobile from './images/mobile/caseImages/case_5.jpg?src-loader'

import label1Mobile from './images/mobile/labelsImages/label_1.jpg?src-loader'
import label2Mobile from './images/mobile/labelsImages/label_2.jpg?src-loader'
import label3Mobile from './images/mobile/labelsImages/label_3.jpg?src-loader'
import label4Mobile from './images/mobile/labelsImages/label_4.jpg?src-loader'
import label5Mobile from './images/mobile/labelsImages/label_5.jpg?src-loader'


type CaseImagesAnimationProps = {
  className: string
}

const images = {
  case: {
    desktop: [
      case1Desktop, case2Desktop, case3Desktop, case4Desktop, case5Desktop ],
    mobile: [
      case1Mobile, case2Mobile, case3Mobile, case4Mobile, case5Mobile,
    ],
  },
  label: {
    desktop: [
      label1Desktop, label2Desktop, label3Desktop, label4Desktop, label5Desktop,
    ],
    mobile: [
      label1Mobile, label2Mobile, label3Mobile, label4Mobile, label5Mobile,
    ],
  },
}

const CaseImagesAnimation: React.FC<CaseImagesAnimationProps> = ({ className }) => {
  const { isMobile } = useDevice()
  const prefersReducedMotion = usePrefersReducedMotion()
  const [ vialIndex, setVialIndex ] = useState(0)
  const [ caseIndex, setCaseIndex ] = useState(0)

  const vialImage = isMobile ? vialImageMobile : vialImageDesktop
  const caseImages = isMobile ? images.case.mobile : images.case.desktop
  const labelImages = isMobile ? images.label.mobile : images.label.desktop

  useEffect(() => {
    const interval = setInterval(() => {
      setVialIndex((prev) => (prev + 1) % labelImages.length)
      setCaseIndex((prev) => (prev + 1) % caseImages.length)
    }, prefersReducedMotion ? 3000 : 1000)

    return () => clearInterval(interval)
  }, [ caseImages.length, labelImages.length, prefersReducedMotion ])

  return (
    <div className={cx(className, 'flex flex-col gap-4')}>
      <Image
        priority
        src={vialImage}
        width={vialImage.width}
        height={vialImage.height}
        alt="Scentbird vial image"
      />
      <div className={cx('absolute w-auto', isMobile ? 'left-88 top-6 h-[38rem] w-[376rem]' : 'left-[128rem] top-[9rem] h-48 w-[496rem] ')}>
        <Image
          src={labelImages[vialIndex]}
          width={labelImages[vialIndex].width}
          height={labelImages[vialIndex].height}
          className="size-full object-contain"
          alt="Scentbird brand label image"
        />
      </div>
      <div className={cx('relative overflow-hidden', isMobile ? 'w-[376rem]' : 'w-[496rem]')}>
        <Image
          priority
          src={caseImages[caseIndex]}
          width={caseImages[caseIndex].width}
          height={caseImages[caseIndex].height}
          className="size-full object-contain"
          alt="Scentbird case image"
        />
      </div>
    </div>
  )
}

export default CaseImagesAnimation
